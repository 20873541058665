import React from "react"

import { CheckIcon } from "@heroicons/react/solid"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Hero = ({ data }) => {
	return (
		<div className="grid w-full" style={{ height: "964px" }}>
			<GatsbyImage
				alt=""
				image={getImage(data?.imageNode)}
				style={{
					gridArea: "1/1",
					width: "100%",
				}}
				imgStyle={{
					gridArea: "1/1",
					width: "100%",
					objectFit: "cover",
					objectPosition: "70% 20%",
				}}
				loading="eager"
			/>

			<div
				className="grid place-items-center relative bg-[#00f] bg-opacity-30"
				style={{
					gridArea: "1/1",
					textShadow: "1px 1px black, 0 0 3px gray",
				}}
			>
				<div className="sectionwidth sectionpadding w-full text-white">
					<div className="max-w-4xl mr-auto">
						<h1 className="h1 font-bold mb-3 hyphens sm:hyphens-none">
							{data?.h1}
						</h1>
						<ul className="h3 font-bold mb-3 hyphens sm:hyphens-none max-w-4xl">
							{data?.checklist?.map((item, index) => {
								return (
									<li key={index} className="flex items-start">
										<CheckIcon className="h-8 w-8 lg:h-9 lg:w-9 mr-2 flex-shrink-0 stroke-current stroke-2"></CheckIcon>
										{item}
									</li>
								)
							})}
						</ul>
						<p className="font-bold text-base lg:text-2xl mb-3 max-w-4xl">
							{data?.text}
						</p>
						{data?.btnLink && (
							<AnchorLink
								to={data?.btnLink}
								className="btn btn-secondary textShadow-none relative z-10"
								stripHash={true}
							>
								{data?.btnText}
							</AnchorLink>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Hero
