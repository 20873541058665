import React from "react"

import Layout from "../components/layout"
import Hero from "../components/sections/hero"
import Seo from "../components/seo"

import { StaticQuery, graphql } from "gatsby"

const Datenschutz = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					hero: file(relativePath: { eq: "datenschutz_hero.png" }) {
						childImageSharp {
							gatsbyImageData(placeholder: TRACED_SVG)
						}
					}
				}
			`}
			render={(data) => (
				<Layout>
					<Seo noIndex={true} />

					<Hero
						data={{
							h1: "Datenschutz",
							image: "datenschutz_hero.png",
							imageNode: data.hero,
						}}
					></Hero>

					<div
						className="bg-white prose prose-primary sectionwidth sectionpadding break-all"
						id="datenschutz"
					>
						<h1>Datenschutzerklärung</h1>
						<p>
							<strong>1. Datenschutz auf einen Blick</strong>
						</p>
						<p>
							<strong>Allgemeine Hinweise</strong>
						</p>
						<p>
							Die folgenden Hinweise geben einen einfachen Überblick darüber,
							was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere
							Website besuchen. Personenbezogene Daten sind alle Daten, mit
							denen Sie persönlich identifiziert werden können. Ausführliche
							Informationen zum Thema Datenschutz entnehmen Sie unserer unter
							diesem Text aufgeführten Datenschutzerklärung.
						</p>
						<p>
							<strong>Datenerfassung auf unserer Website</strong>
						</p>
						<p>
							<strong>
								Wer ist verantwortlich für die Datenerfassung auf dieser
								Website?
							</strong>
						</p>
						<p>
							Die Datenverarbeitung auf dieser Website erfolgt durch den
							Websitebetreiber. Dessen Kontaktdaten können Sie dem
							Impressum&nbsp;dieser Website entnehmen.
						</p>
						<p>
							<strong>Wie erfassen wir Ihre Daten?</strong>
						</p>
						<p>
							Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
							mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
							ein Kontaktformular eingeben.
						</p>
						<p>
							Andere Daten werden automatisch beim Besuch der Website durch
							unsere IT-Systeme erfasst. Das sind vor allem technische Daten
							(z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
							Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
							sobald Sie unsere Website betreten.
						</p>
						<p>
							<strong>Wofür nutzen wir Ihre Daten?</strong>
						</p>
						<p>
							Ein Teil der Daten wird erhoben, um eine fehlerfreie
							Bereitstellung der Website zu gewährleisten. Andere Daten können
							zur Analyse Ihres Nutzerverhaltens verwendet werden.
						</p>
						<p>
							<strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
						</p>
						<p>
							Sie haben jederzeit das Recht unentgeltlich Auskunft über
							Herkunft, Empfänger und Zweck Ihrer gespeicherten
							personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
							die Berichtigung, Sperrung oder Löschung dieser Daten zu
							verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
							können Sie sich jederzeit unter der im Impressum angegebenen
							Adresse an uns wenden. Des Weiteren steht Ihnen ein
							Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
						</p>
						<p>
							<strong>Analyse-Tools und Tools von Drittanbietern</strong>
						</p>
						<p>
							Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
							ausgewertet werden. Das geschieht vor allem mit Cookies und mit
							sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
							erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu
							Ihnen zurückverfolgt werden. Sie können dieser Analyse
							widersprechen oder sie durch die Nichtbenutzung bestimmter Tools
							verhindern. Detaillierte Informationen dazu finden Sie in der
							folgenden Datenschutzerklärung.
						</p>
						<p>
							Sie können dieser Analyse widersprechen. Über die
							Widerspruchsmöglichkeiten werden wir Sie in dieser
							Datenschutzerklärung informieren.
						</p>
						<p>
							<strong>2. Allgemeine Hinweise und Pflichtinformationen</strong>
						</p>
						<p>
							<strong>Datenschutz</strong>
						</p>
						<p>
							Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
							Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
							vertraulich und entsprechend der gesetzlichen
							Datenschutzvorschriften sowie dieser Datenschutzerklärung.
						</p>
						<p>
							Wenn Sie diese Website benutzen, werden verschiedene
							personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
							mit denen Sie persönlich identifiziert werden können. Die
							vorliegende Datenschutzerklärung erläutert, welche Daten wir
							erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
							welchem Zweck das geschieht.
						</p>
						<p>
							Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
							bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
							kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
							Dritte ist nicht möglich.
						</p>
						<p>
							<strong>Hinweis zur verantwortlichen Stelle</strong>
						</p>
						<p>
							Die verantwortliche Stelle für die Datenverarbeitung auf dieser
							Website ist:
						</p>
						<p>
							D.I.E. STRATEGIE GmbH
							<br />
							Ensingerstr. 33/2
							<br />
							89073 Ulm
						</p>
						<p>
							Telefon: 0731 1461789 1
							<br />
							E-Mail:&nbsp;
							<a href="mailto:datenschutz@internes-recruiting.de">
								datenschutz@internes-recruiting.de
							</a>
						</p>
						<p>
							Unsere Unternehmensgröße entbindet uns einen
							Datenschutzbeauftragten zu bestellen.
						</p>
						<p>
							Bei Fragen wenden Sie sich bitte an die Geschäftsführung (
							<a href="mailto:datenschutz@internes-recruiting.de">
								datenschutz@internes-recruiting.de
							</a>
							).
						</p>
						<p>
							<strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
						</p>
						<p>
							Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
							Einwilligung möglich. Sie können eine bereits erteilte
							Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
							Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
							Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
							unberührt.
						</p>
						<p>
							<strong>
								Beschwerderecht bei der zuständigen Aufsichtsbehörde
							</strong>
						</p>
						<p>
							Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
							Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
							Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist
							der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
							Unternehmen seinen Sitz hat. Eine Liste der
							Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem
							Link entnommen werden:&nbsp;
							<a
								href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
								target="_blank"
								rel="noopener"
							>
								https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
							</a>
							.
						</p>
						<p>
							<strong>Recht auf Datenübertragbarkeit</strong>
						</p>
						<p>
							Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
							Einwilligung oder in Erfüllung eines Vertrags automatisiert
							verarbeiten, an sich oder an einen Dritten in einem gängigen,
							maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
							direkte Übertragung der Daten an einen anderen Verantwortlichen
							verlangen, erfolgt dies nur, soweit es technisch machbar ist.
						</p>
						<p>
							<strong>SSL- bzw. TLS-Verschlüsselung</strong>
						</p>
						<p>
							Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
							Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die
							Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
							TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
							daran, dass die Adresszeile des Browsers von “http://” auf
							“https://” wechselt und an dem Schloss-Symbol in Ihrer
							Browserzeile.
						</p>
						<p>
							Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
							Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
							werden.
						</p>
						<p>
							<strong>Auskunft, Sperrung, Löschung</strong>
						</p>
						<p>
							Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
							jederzeit das Recht auf unentgeltliche Auskunft über Ihre
							gespeicherten personenbezogenen Daten, deren Herkunft und
							Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
							auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
							sowie zu weiteren Fragen zum Thema personenbezogene Daten können
							Sie sich jederzeit unter der im Impressum angegebenen Adresse an
							uns wenden.
						</p>
						<p>
							<strong>Widerspruch gegen Werbe-Mails</strong>
						</p>
						<p>
							Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
							Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
							Werbung und Informationsmaterialien wird hiermit widersprochen.
							Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
							Schritte im Falle der unverlangten Zusendung von
							Werbeinformationen, etwa durch Spam-E-Mails, vor.
						</p>
						<p>
							<strong>3. Datenerfassung auf unserer Website</strong>
						</p>
						<p>
							<strong>Cookies</strong>
						</p>
						<p>
							Die Internetseiten verwenden teilweise so genannte Cookies.
							Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
							keine Viren. Cookies dienen dazu, unser Angebot
							nutzerfreundlicher, effektiver und sicherer zu machen. Cookies
							sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und
							die Ihr Browser speichert.
						</p>
						<p>
							Die meisten der von uns verwendeten Cookies sind so genannte
							“Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
							gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert
							bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren
							Browser beim nächsten Besuch wiederzuerkennen.
						</p>
						<p>
							Sie können Ihren Browser so einstellen, dass Sie über das Setzen
							von Cookies informiert werden und Cookies nur im Einzelfall
							erlauben, die Annahme von Cookies für bestimmte Fälle oder
							generell ausschließen sowie das automatische Löschen der Cookies
							beim Schließen des Browser aktivieren. Bei der Deaktivierung von
							Cookies kann die Funktionalität dieser Website eingeschränkt sein.
						</p>
						<p>
							Cookies, die zur Durchführung des elektronischen
							Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von
							Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich
							sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
							gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse
							an der Speicherung von Cookies zur technisch fehlerfreien und
							optimierten Bereitstellung seiner Dienste. Soweit andere Cookies
							(z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert
							werden, werden diese in dieser Datenschutzerklärung gesondert
							behandelt.
						</p>
						<p>
							<strong>Server-Log-Dateien</strong>
						</p>
						<p>
							Der Provider der Seiten erhebt und speichert automatisch
							Informationen in so genannten Server-Log-Dateien, die Ihr Browser
							automatisch an uns übermittelt. Dies sind:
						</p>
						<ul>
							<li>Browsertyp und Browserversion</li>
							<li>verwendetes Betriebssystem</li>
							<li>Referrer URL</li>
							<li>Hostname des zugreifenden Rechners</li>
							<li>Uhrzeit der Serveranfrage</li>
							<li>IP-Adresse</li>
						</ul>
						<p>
							Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
							nicht vorgenommen.
						</p>
						<p>
							Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
							DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
							oder vorvertraglicher Maßnahmen gestattet.
						</p>
						<p>
							<strong>Kontaktformular</strong>
						</p>
						<p>
							Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
							Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
							angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
							den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
							geben wir nicht ohne Ihre Einwilligung weiter.
						</p>
						<p>
							Die Verarbeitung der in das Kontaktformular eingegebenen Daten
							erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung
							(Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
							jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
							E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
							Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
						</p>
						<p>
							Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
							uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
							Speicherung widerrufen oder der Zweck für die Datenspeicherung
							entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
							Zwingende gesetzliche Bestimmungen – insbesondere
							Aufbewahrungsfristen – bleiben unberührt.
						</p>
						<p>
							<strong>Registrierung auf dieser Website</strong>
						</p>
						<p>
							Sie können sich auf unserer Website registrieren, um zusätzliche
							Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
							verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes
							oder Dienstes, für den Sie sich registriert haben. Die bei der
							Registrierung abgefragten Pflichtangaben müssen vollständig
							angegeben werden. Anderenfalls werden wir die Registrierung
							ablehnen.
						</p>
						<p>
							Für wichtige Änderungen etwa beim Angebotsumfang oder bei
							technisch notwendigen Änderungen nutzen wir die bei der
							Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu
							informieren.
						</p>
						<p>
							Die Verarbeitung der bei der Registrierung eingegebenen Daten
							erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
							DSGVO). Sie können eine von Ihnen erteilte Einwilligung jederzeit
							widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
							uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung
							bleibt vom Widerruf unberührt.
						</p>
						<p>
							Die bei der Registrierung erfassten Daten werden von uns
							gespeichert, solange Sie auf unserer Website registriert sind und
							werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen
							bleiben unberührt.
						</p>
						<p>
							<strong>Verarbeiten von Daten (Kunden- und Vertragsdaten)</strong>
						</p>
						<p>
							Wir erheben, verarbeiten und nutzen personenbezogene Daten nur,
							soweit sie für die Begründung, inhaltliche Ausgestaltung oder
							Änderung des Rechtsverhältnisses erforderlich sind
							(Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
							b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines
							Vertrags oder vorvertraglicher Maßnahmen gestattet.
							Personenbezogene Daten über die Inanspruchnahme unserer
							Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir
							nur, soweit dies erforderlich ist, um dem Nutzer die
							Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
						</p>
						<p>
							Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
							Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
							Aufbewahrungsfristen bleiben unberührt.
						</p>
						<p>
							<strong>4. Analyse Tools und Werbung</strong>
						</p>
						<p>
							<strong>Google Analytics</strong>
						</p>
						<p>
							Diese Website nutzt Funktionen des Webanalysedienstes Google
							Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre
							Parkway, Mountain View, CA 94043, USA.
						</p>
						<p>
							Google Analytics verwendet so genannte „Cookies“. Das sind
							Textdateien, die auf Ihrem Computer gespeichert werden und die
							eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
							durch den Cookie erzeugten Informationen über Ihre Benutzung
							dieser Website werden in der Regel an einen Server von Google in
							den USA übertragen und dort gespeichert.
						</p>
						<p>
							Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage
							von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
							berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
							sowohl sein Webangebot als auch seine Werbung zu optimieren.
						</p>
						<p>
							<strong>IP Anonymisierung</strong>
						</p>
						<p>
							Wir haben auf dieser Website die Funktion IP-Anonymisierung
							aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
							Mitgliedstaaten der Europäischen Union oder in anderen
							Vertragsstaaten des Abkommens über den Europäischen
							Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
							Ausnahmefällen wird die volle IP-Adresse an einen Server von
							Google in den USA übertragen und dort gekürzt. Im Auftrag des
							Betreibers dieser Website wird Google diese Informationen
							benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
							die Websiteaktivitäten zusammenzustellen und um weitere mit der
							Websitenutzung und der Internetnutzung verbundene Dienstleistungen
							gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
							Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
							nicht mit anderen Daten von Google zusammengeführt.
						</p>
						<p>
							<strong>Browser Plugin</strong>
						</p>
						<p>
							Sie können die Speicherung der Cookies durch eine entsprechende
							Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
							jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
							sämtliche Funktionen dieser Website vollumfänglich werden nutzen
							können. Sie können darüber hinaus die Erfassung der durch den
							Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
							(inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
							Daten durch Google verhindern, indem Sie das unter dem folgenden
							Link verfügbare Browser-Plugin herunterladen und
							installieren:&nbsp;
							<a
								href="https://tools.google.com/dlpage/gaoptout?hl=de"
								target="_blank"
								rel="noopener"
							>
								https://tools.google.com/dlpage/gaoptout?hl=de
							</a>
							.
						</p>
						<p>
							<strong>Widerspruch gegen Datenerfassung</strong>
						</p>
						<p>
							Sie können die Erfassung Ihrer Daten durch Google Analytics
							verhindern, indem Sie auf folgenden Link klicken. Es wird ein
							Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
							zukünftigen Besuchen dieser Website verhindert:&nbsp;
							<button
								onClick={() => {
									if (typeof window !== "undefined") window.gaOptout()
								}}
							>
								<strong>Google Analytics deaktivieren</strong>.
							</button>
						</p>
						<p>
							Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
							finden Sie in der Datenschutzerklärung von Google:&nbsp;
							<a
								href="https://support.google.com/analytics/answer/6004245?hl=de"
								target="_blank"
								rel="noopener"
							>
								https://support.google.com/analytics/answer/6004245?hl=de
							</a>
							.
						</p>
						<p>
							<strong>Auftragsdatenverarbeitung</strong>
						</p>
						<p>
							Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
							abgeschlossen und setzen die strengen Vorgaben der deutschen
							Datenschutzbehörden bei der Nutzung von Google Analytics
							vollständig um.
						</p>
						<p>
							<strong>Demografische Merkmale bei Google Analytics</strong>
						</p>
						<p>
							Diese Website nutzt die Funktion “demografische Merkmale” von
							Google Analytics. Dadurch können Berichte erstellt werden, die
							Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher
							enthalten. Diese Daten stammen aus interessenbezogener Werbung von
							Google sowie aus Besucherdaten von Drittanbietern. Diese Daten
							können keiner bestimmten Person zugeordnet werden. Sie können
							diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem
							Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch
							Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung”
							dargestellt generell untersagen.
						</p>
						<p>
							<strong>5. Google Fonts Datenschutzerklärung</strong>
						</p>
						<p>
							Auf unserer Website verwenden wir Google Fonts. Das sind die
							“Google-Schriften” der Firma Google Inc. Für den europäischen Raum
							ist das Unternehmen Google Ireland Limited (Gordon House, Barrow
							Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.
						</p>
						<p>
							Für die Verwendung von Google-Schriftarten müssen Sie sich nicht
							anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine
							Cookies in Ihrem Browser gespeichert. Die Dateien (CSS,
							Schriftarten/Fonts) werden über die Google-Domains
							fonts.googleapis.com und fonts.gstatic.com angefordert. Laut
							Google sind die Anfragen nach CSS und Schriften vollkommen
							getrennt von allen anderen Google-Diensten. Wenn Sie ein
							Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre
							Google-Kontodaten, während der Verwendung von Google Fonts, an
							Google übermittelt werden. Google erfasst die Nutzung von CSS
							(Cascading Style Sheets) und der verwendeten Schriftarten und
							speichert diese Daten sicher. Wie die Datenspeicherung genau
							aussieht, werden wir uns noch im Detail ansehen.
						</p>
						<p>
							<strong>Was sind Google Fonts?</strong>
						</p>
						<p>
							Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit
							über 800 Schriftarten, die Google seinen Nutzern kostenlos zu
							Verfügung stellt. Viele dieser Schriftarten sind unter
							der&nbsp;SIL Open Font License&nbsp;veröffentlicht, während andere
							unter der&nbsp;Apache-Lizenz&nbsp;veröffentlicht wurden. Beides
							sind&nbsp;freie Software-Lizenzen.
						</p>
						<p>
							<strong>
								Warum verwenden wir Google Fonts auf unserer Webseite?
							</strong>
						</p>
						<p>
							Mit Google Fonts können wir auf unserer eigenen Webseite Schriften
							nutzen, doch müssen sie nicht auf unseren eigenen Server
							hochladen. Google Fonts ist ein wichtiger Baustein, um die
							Qualität unserer Webseite hoch zu halten. Alle Google-Schriften
							sind automatisch für das Web optimiert und dies spart Datenvolumen
							und ist speziell für die Verwendung mit mobilen Endgeräten ein
							großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige
							Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google
							Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme
							(Rendering) in verschiedenen Browsern, Betriebssystemen und
							mobilen Endgeräten können zu Fehlern führen. Solche Fehler können
							teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des
							schnellen Content Delivery Networks (CDN) gibt es mit Google Fonts
							keine plattformübergreifenden Probleme. Google Fonts unterstützt
							alle gängigen Browser (Google Chrome,&nbsp;Mozilla
							Firefox,&nbsp;Apple Safari,&nbsp;Opera) und funktioniert
							zuverlässig auf den meisten modernen mobilen Betriebssystemen,
							einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir
							verwenden die Google Fonts also, damit wir unser gesamtes
							Online-Service so schön und einheitlich wie möglich darstellen
							können.
						</p>
						<p>
							<strong>Welche Daten werden von Google gespeichert?</strong>
						</p>
						<p>
							Wenn Sie unsere Webseite besuchen, werden die Schriften über einen
							Google-Server nachgeladen. Durch diesen externen Aufruf werden
							Daten an die Google-Server übermittelt. So erkennt Google auch,
							dass Sie bzw. Ihre IP-Adresse unsere Webseite besuchen. Die Google
							Fonts API wurde entwickelt, um Verwendung, Speicherung und
							Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine
							ordentliche Bereitstellung von Schriften nötig ist.&nbsp;API steht
							übrigens für „Application Programming Interface“ und dient unter
							anderem als Datenübermittler im Softwarebereich.
						</p>
						<p>
							Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google
							und ist somit geschützt.&nbsp;Durch die gesammelten Nutzungszahlen
							kann Google feststellen, wie gut die einzelnen Schriften ankommen.
							Die Ergebnisse veröffentlicht Google auf internen Analyseseiten,
							wie beispielsweise Google Analytics. Zudem verwendet Google auch
							Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten
							Google-Schriften verwenden. Diese Daten werden in der
							BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer
							und Entwickler nützen das Google-Webservice&nbsp;BigQuery, um
							große Datenmengen untersuchen und bewegen zu können.
						</p>
						<p>
							Zu bedenken gilt allerdings noch, dass durch jede Google Font
							Anfrage auch Informationen wie&nbsp;Spracheinstellungen,
							IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers
							und Name des Browsers automatisch an die Google-Server übertragen
							werden. Ob diese Daten auch gespeichert werden, ist nicht klar
							feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
						</p>
						<p>
							<strong>Wie lange und wo werden die Daten gespeichert?</strong>
						</p>
						<p>
							Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen
							Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das
							ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten
							zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man
							einfach und schnell z.B. das Design bzw. die Schriftart einer
							Webseite ändern kann.
						</p>
						<p>
							Die Font-Dateien werden bei Google ein Jahr gespeichert. Google
							verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich
							zu verbessern. Wenn Millionen von Webseiten auf die gleichen
							Schriften verweisen, werden sie nach dem ersten Besuch
							zwischengespeichert und erscheinen sofort auf allen anderen später
							besuchten Webseiten wieder. Manchmal aktualisiert Google
							Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von
							Sprache zu erhöhen und das Design zu verbessern.
						</p>
						<p>
							<strong>
								Wie kann ich meine Daten löschen bzw. die Datenspeicherung
								verhindern?
							</strong>
						</p>
						<p>
							Jene Daten, die Google für einen Tag bzw. ein Jahr speichert
							können nicht einfach gelöscht werden. Die Daten werden beim
							Seitenaufruf automatisch an Google übermittelt. Um diese Daten
							vorzeitig löschen zu können, müssen Sie den Google-Support
							auf&nbsp;
							<a
								href="https://support.google.com/?hl=de&amp;tid=331594877967"
								target="_blank"
								rel="noopener"
							>
								https://support.google.com/?hl=de&amp;tid=331594877967
							</a>
							&nbsp;kontaktieren. Datenspeicherung verhindern Sie in diesem Fall
							nur, wenn Sie unsere Seite nicht besuchen.
						</p>
						<p>
							Anders als andere Web-Schriften erlaubt uns Google
							uneingeschränkten Zugriff auf alle Schriftarten. Wir können also
							unlimitiert auf ein Meer an Schriftarten zugreifen und so das
							Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und
							weiteren Fragen finden Sie auf&nbsp;
							<a
								href="https://developers.google.com/fonts/faq?tid=331594877967"
								target="_blank"
								rel="noopener"
							>
								https://developers.google.com/fonts/faq?tid=331594877967
							</a>
							. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten
							ein, doch wirklich detaillierte Informationen über
							Datenspeicherung sind nicht enthalten. Es ist relativ schwierig,
							von Google wirklich präzise Informationen über gespeicherten Daten
							zu bekommen.
						</p>
						<p>
							Welche Daten grundsätzlich von Google erfasst werden und wofür
							diese Daten verwendet werden, können Sie auch auf&nbsp;
							<a
								href="https://policies.google.com/privacy?hl=de&amp;tid=331594877967"
								target="_blank"
								rel="noopener"
							>
								https://www.google.com/intl/de/policies/privacy/
							</a>
							&nbsp;nachlesen.
						</p>
						<p>
							(Quelle: Erstellt mit dem&nbsp;
							<a
								title="Datenschutz Generator Deutschland"
								href="https://www.adsimple.de/datenschutz-generator/"
								target="_blank"
								rel="follow noopener noreferrer"
							>
								Datenschutz Generator
							</a>
							&nbsp;von AdSimple&nbsp;in Kooperation mit&nbsp;
							<a
								title=""
								href="https://www.hashtagmann.de/"
								target="_blank"
								rel="follow noopener noreferrer"
							>
								hashtagmann.de
							</a>
							)
						</p>
						<h3>
							<strong>
								Datenschutzerklärung/Informationspflichten nach Artikel 13 und
								14 DSGVO
							</strong>
						</h3>
						<p>
							Mit den folgenden Angaben informieren wir Sie über die
							Verarbeitung Ihrer personenbezogenen Daten durch uns im
							Zusammenhang mit der bestehenden oder zukünftigen
							Vertragsbeziehung sowie über die Ihnen zustehenden Rechte.
						</p>
						<p>
							Welche Daten im Einzelnen verarbeitet und in welcher Weise sie
							genutzt werden, richtet sich maßgeblich nach den jeweils mit Ihnen
							vereinbarten Leistungen. Details ergeben sich somit aus den
							jeweiligen Vereinbarungen und Geschäftsbedingungen.
						</p>
						<p>
							Bitte geben Sie die Informationen auch unseren Ansprechpartnern in
							Ihrem Unternehmen und den aktuellen und künftigen
							vertretungsberechtigten Personen weiter.
						</p>
						<p>
							<strong>1. Für die Datenverarbeitung Verantwortlicher</strong>
						</p>
						<p>
							Die verantwortliche Stelle für die Datenverarbeitung auf dieser
							Website ist:
						</p>
						<p>
							D.I.E. STRATEGIE GmbH
							<br />
							Ensingerstr. 33/2
							<br />
							89073 Ulm
						</p>
						<p>
							Telefon: 030 / 23 59 51 28
							<br />
							E-Mail:{" "}
							<a href="mailto:datenschutz@internes-recruiting.de">
								datenschutz@internes-recruiting.de
							</a>
						</p>
						<p>
							Unsere Unternehmensgröße entbindet uns einen
							Datenschutzbeauftragten zu bestellen.
						</p>
						<p>
							Bei Fragen wenden Sie sich bitte an die Geschäftsführung (
							<a href="mailto:datenschutz@internes-recruiting.de">
								datenschutz@internes-recruiting.de
							</a>
							).
						</p>
						<p>
							<strong>2. Kontaktdaten des Datenschutzbeauftragten</strong>
						</p>
						<p>
							Unsere Unternehmensgröße entbindet uns einen
							Datenschutzbeauftragten zu bestellen.
						</p>
						<p>
							Bei Fragen wenden Sie sich bitte an die Geschäftsführung (
							<a href="mailto:datenschutz@internes-recruiting.de">
								datenschutz@internes-recruiting.de
							</a>
							).
						</p>
						<p>
							<strong>3. Kategorien personenbezogener Daten</strong>
						</p>
						<p>
							Wir verarbeiten personenbezogene Daten, die wir im Zusammenhang
							mit einer Vertragsanbahnung (z. B. Anfrage, Telefon- oder
							E-Mailkontakt, Angebotserstellung, Veranstaltungsbuchung) oder aus
							der Vertragsbeziehung mit Ihnen erhalten.
						</p>
						<p>
							Die Daten erhalten wir entweder direkt von Ihnen oder aus
							öffentlich zugänglichen Quellen (z. B. Handelsregister,
							berufsbezogenen soziale Netzwerke oder frei zugängliche Angaben
							auf Homepages) oder sie werden aufgrund gesetzlicher Vorschriften
							erhoben und verarbeitet.
						</p>
						<p>
							Es können insbesondere folgende Daten bzw. Kategorien von Daten
							verarbeitet werden:
						</p>
						<ul>
							<li>
								Stammdaten, wie Vorname, Nachname, Firmen-Anschrift, zugehöriges
								Unternehmen, Funktion, ggf. Geburtsdatum
							</li>
							<li>
								Kontaktdaten, wie E-Mailadresse, Telefonnummer (Festnetz
								und/oder Mobilfunk)
							</li>
							<li>
								Namen, E-Mailadressen, Telefonnummern und weitere (Kontakt-)
								Daten der jeweiligen Ansprechpartner bzw. Ihrer Mitarbeiter
							</li>
							<li>
								Vertragsdaten, wie Kundennummer, Bankverbindung,
								Steuernummer/USt-ID
							</li>
							<li>Bonitätsdaten</li>
							<li>Zahlungs- und Abrechnungsdaten</li>
							<li>Nutzungsdaten und -protokolle</li>
							<li>
								Korrespondenzdaten (z. B. Schriftverkehr mit Ihnen),
								einschließlich E-Mails, die entstehen, wenn Sie uns kontaktieren
							</li>
							<li>
								Absatzdaten (Art und Menge der im betreffenden Zeitraum
								bezogenen Produkte)
							</li>
							<li>
								Fotos, die Sie uns zur Verfügung stellen oder die im Rahmen von
								Veranstaltungen, deren Teilnehmer/Besucher Sie sind, gemacht
								werden
							</li>
							<li>
								personenbezogene Daten, die wir aus öffentlich zugänglichen
								Quellen (z. B. Handelsregister, Bonitätsinformationen der
								Creditreform) zulässigerweise verarbeiten dürfen
							</li>
							<li>
								Endkundendaten, sofern sie für die Bestellung von Lizenzen oder
								die Erhebung von Projektpreisanfragen benötigt werden
							</li>
							<li>
								sowie andere mit den genannten Kategorien vergleichbare Daten.
							</li>
							<li>&nbsp;</li>
						</ul>
						<p>
							<strong>
								4. Zweck der Datenverarbeitung und Rechtsgrundlage
							</strong>
						</p>
						<p>
							<strong>Zur Durchführung von Verträgen</strong>
							<br />
							Wir verarbeiten Ihre Daten zur Anbahnung und Durchführung unserer
							Vertragsbeziehung mit Ihnen. Die Verarbeitung erfolgt u.a. zu
							Zwecken der Auftragsabwicklung, zur Erstellung von Abrechnungen
							und Gutschriften, zur Verwaltung und Abwicklung von Verträgen,
							Verwaltung und Durchsetzung von Forderungen sowie im Interesse
							einer umfassenden Kundenbetreuung. Die konkreten Zwecke der
							Datenverarbeitung richten sich in erster Linie nach dem jeweiligen
							Vertrag. Weitere Einzelheiten zum Zweck der Datenverarbeitung
							können Sie den maßgeblichen Vertragsunterlagen und
							Geschäftsbedingungen entnehmen. Rechtsgrundlage für diese
							Datenverarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.
						</p>
						<p>
							<strong>Zur Erfüllung rechtlicher Verpflichtungen</strong>
							<br />
							Darüber hinaus verarbeiten wir Ihre Daten zur Erfüllung
							gesetzlicher Verpflichtungen, denen wir unterliegen. Hierzu zählen
							insbesondere steuer- und handelsrechtliche
							Aufbewahrungsvorschriften, Pflichten im Zusammenhang mit der
							gesetzlichen Buchführung oder Vorschriften zur Risikobewertung,
							Betrugs- und Geldwäscheprävention. Rechtsgrundlage für diese
							Datenverarbeitung ist Art. 6 Abs. 1 lit. c) DSGVO.
						</p>
						<p>
							<strong>Zur Wahrung berechtigter Interessen</strong>
							<br />
							Wir verarbeiten personenbezogene Daten zur Wahrung berechtigter
							eigener Interessen oder Interessen Dritter, sofern nicht die
							Interessen oder Grundrechte und Grundfreiheiten der betroffenen
							Person, die den Schutz personenbezogener Daten erfordern,
							überwiegen. Rechtsgrundlage für diese Datenverarbeitung ist Art. 6
							Abs. 1 lit. f) DSGVO.
						</p>
						<p>Zu unseren berechtigten Interessen zählen insbesondere</p>
						<ul>
							<li>
								Optimierung von Verfahren zur Bedarfsanalyse zwecks direkter
								Kundenansprache
							</li>
							<li>Distributionsanalysen und Vertriebssteuerung</li>
							<li>Beurteilung der Wirtschaftlichkeit der Geschäftsbeziehung</li>
							<li>
								Maßnahmen zur Geschäftssteuerung und Weiterentwicklung von
								Dienstleistungen und Produkten
							</li>
							<li>
								Datenaustausch mit Auskunfteien zur Ermittlung von Bonitäts- und
								Ausfallrisiken
							</li>
							<li>
								Sicherheitsvorkehrungen zur Gewährleistung des IT-Betriebs und
								der IT-Sicherheit
							</li>
						</ul>
						<p>
							<strong>Aufgrund Ihrer Einwilligung</strong>
							<br />
							Wenn und soweit Sie uns für bestimmte Zwecke (z. B. Erhalt eines
							Newsletters) eine Einwilligung zur Verarbeitung Ihrer
							personenbezogenen Daten erteilt haben, ist die jeweilige
							Einwilligung gemäß Art. 6 Abs. 1 lit a) DSGVO Rechtsgrundlage für
							die Datenverarbeitung. Sie können die Einwilligung zur
							Datenverarbeitung jederzeit mit Wirkung für die Zukunft
							widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
							Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
							Bitte beachten Sie auch, dass wir im Einzelfall berechtigt sein
							können, die Datenverarbeitung oder Teile davon auf der Grundlage
							eines gesetzlichen Erlaubnistatbestandes (z.B. Art. 6 Abs. 1 lit.
							f) DSGVO) fortzuführen. Hierüber werden Sie in diesem Fall
							gesondert informiert.
						</p>
						<p>
							<strong>5. Weitergabe von Daten an Dritte</strong>
						</p>
						<p>
							Eine Weitergabe Ihrer Daten an Dritte außerhalb der D.I.E.
							STRATEGIE GmbH erfolgt nur, sofern Sie vorher ausdrücklich in die
							Übermittlung eingewilligt haben, wir aufgrund gesetzlicher
							Vorschriften hierzu verpflichtet sind oder eine gesetzliche
							Übermittlungsbefugnis (siehe im Folgenden) besteht.
						</p>
						<p>
							Innerhalb der D.I.E. STRATEGIE GmbH werden Ihre Daten u.a. zur
							Durchführung oder Einleitung der Geschäftsbeziehung mit Ihnen
							weitergegeben. Hierzu werden Ihre personenbezogenen Daten bei uns
							in zentralen Datenbanken für interne Abrechnungs- und
							Buchhaltungszwecke sowie zur Kundenpflege / -betreuung
							gespeichert.
						</p>
						<p>
							Soweit dies nach Art. 6 Abs. 1 lit b) DSGVO für die Durchführung
							eines Vertragsverhältnisses mit Ihnen erforderlich ist, werden
							Ihre personenbezogenen Daten an Dritte weitergegeben. Hierzu
							zählen die Weitergabe an die uns angeschlossenen Dienstleister und
							Distributoren, damit Sie bei Ihren Bestellungen dort als
							Vertragspartner identifiziert werden können; ebenso die Weitergabe
							Ihres Namens und der Firma an Dozenten, wenn Sie ein Seminar
							gebucht haben. Im Bereich der Zentralregulierung geben wir Ihre
							Daten an den Zentralregulierer weiter.
						</p>
						<p>
							Zur Erfüllung unserer Aufgaben und zur Vertragserfüllung (z. B.
							zum Versand von Newslettern) nutzen wir zum Teil externe
							Dienstleister und Auftragnehmer, die im erforderlichen Umfang
							Zugriff auf Ihre Daten erhalten und diese ausschließlich für die
							Erfüllung der erteilten Aufträge nutzen dürfen. Die Auftragnehmer
							sind jeweils sorgfältig ausgesucht und vertraglich nach Art. 28
							DSGVO verpflichtet.
						</p>
						<p>
							<strong>6. Übermittlung von Daten in Drittländer</strong>
						</p>
						<p>
							Die Übermittlung von personenbezogenen Daten in ein Drittland
							findet nicht statt. Die Daten werden ausschließlich in
							Rechenzentren der Bundesrepublik Deutschland verarbeitet.
						</p>
						<p>
							<strong>7. Speicherdauer</strong>
						</p>
						<p>
							Wir verarbeiten und speichern Ihre personenbezogenen Daten solange
							es für die Erfüllung unserer vertraglichen und gesetzlichen
							Pflichten erforderlich ist oder wir eine Erlaubnis in Form einer
							Einwilligung oder eines gesetzlichen Erlaubnistatbestandes hierzu
							haben.
						</p>
						<p>
							Sind die Daten auf dieser Grundlage nicht mehr erforderlich,
							werden diese regelmäßig gelöscht, es sein denn, deren befristete
							Weiterverarbeitung ist aus rechtlichen Gründen erforderlich.
						</p>
						<p>
							<strong>
								8. Pflicht zur Bereitstellung der personenbezogenen Daten
							</strong>
						</p>
						<p>
							Im Rahmen unserer Geschäftsbeziehung müssen Sie in der Regel nur
							diejenigen personenbezogenen Daten bereitstellen, die für die
							Begründung, Durchführung und Beendigung des jeweiligen
							Vertragsverhältnisses erforderlich sind. Andernfalls ist der
							Abschluss des Vertrages, Ihre Teilnahme an einer Veranstaltung
							oder die Bearbeitung Ihres Anliegens nicht möglich; einen
							bestehenden Vertrag werden wir in der Regel nicht mehr durchführen
							können und ggf. beenden müssen. Die Bereitstellung der
							personenbezogenen Daten ist gesetzlich darüber hinaus nicht
							vorgeschrieben.
						</p>
						<p>
							<strong>
								9. Automatisierte Entscheidungsfindung im Einzelfall, Profiling
							</strong>
						</p>
						<p>
							Automatisierte Entscheidungsfindungen gemäß Art. 22 DSGVO,
							einschließlich Profiling, nutzen wir nicht.
						</p>
						<p>
							<strong>10. Ihre Rechte als betroffene Person</strong>
						</p>
						<p>
							Das geltende Datenschutzrecht gewährt Ihnen verschiedene Rechte
							hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten. Sie
							haben das Recht
						</p>
						<ul>
							<li>auf Auskunft nach Artikel 15 DSGVO,</li>
							<li>auf Berichtigung nach Artikel 16 DSGVO,</li>
							<li>auf Löschung nach Artikel 17 DSGVO,</li>
							<li>auf Einschränkung der Verarbeitung nach Artikel 18 DSGVO,</li>
							<li>auf Unterrichtung nach Art. 19 DSGVO,</li>
							<li>auf Datenübertragbarkeit nach Artikel 20 DSGVO,</li>
							<li>auf Widerspruch nach Artikel 21 DSGVO (siehe unten),</li>
							<li>
								auf Beschwerde bei einer Aufsichtsbehörde nach Artikel 77 DSGVO.
							</li>
						</ul>
						<p>
							Beim Auskunftsrecht und beim Löschungsrecht gelten die
							Einschränkungen nach §§ 34 und 35 BDSG.
						</p>
						<p>
							<strong>Recht auf Widerruf einer erteilten Einwilligung</strong>
							<br />
							Sie haben das Recht, eine erteilte Einwilligung zur Verarbeitung
							personenbezogener Daten jederzeit mit Wirkung für die Zukunft zu
							widerrufen. Im Falle des Widerrufs werden wir die betroffenen
							Daten unverzüglich löschen, sofern eine weitere Verarbeitung nicht
							auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung
							gestützt werden kann. Durch den Widerruf der Einwilligung wird die
							Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf
							erfolgten Verarbeitung nicht berührt.
						</p>
						<p>
							<strong>Gesonderter Hinweis auf Ihr Widerspruchsrecht</strong>
							<br />
							Wenn wir Ihre personenbezogenen Daten gemäß Art. 6 Abs. 1 lit. f
							DSGVO im Rahmen einer Interessenabwägung aufgrund unseres
							überwiegenden Interesses verarbeiten, haben Sie das jederzeitige
							Recht, aus Gründen, die sich aus Ihrer besonderen Situation
							ergeben, gegen diese Verarbeitung Widerspruch mit Wirkung für die
							Zukunft einzulegen. Machen Sie von Ihrem Widerspruchsrecht
							Gebrauch, beenden wir die Verarbeitung der betroffenen Daten, es
							sei denn, wir können zwingende schutzwürdige Gründe für die
							Verarbeitung nachweisen, die Ihre Interessen, Rechte und
							Freiheiten überwiegen oder die Verarbeitung dient der
							Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
						</p>
						<p>
							Möchten Sie die vorstehenden Rechte geltend machen, so können Sie
							sich direkt an unsere Geschäftsführung &nbsp;wenden.
						</p>
						<p>&nbsp;</p>
					</div>
				</Layout>
			)}
		/>
	)
}

export default Datenschutz
